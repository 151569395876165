import * as schemaItemTypes from './schemaItemTypes';
import { rules } from './validationRules';
import * as onUpdateEffects from './onUpdateEffects';

const linkedProperties = {
  CLIENT: 'client',
  PROJECT: 'project',
  BUILDING: 'building',
  UNIT: 'unit',
  ROOM: 'room',
};

/**
 * @type {Array<import('./schema').default>}
 */

const clientSchema = [
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'label.client',
    children: [
      {
        type: schemaItemTypes.CLIENT,
        label: 'label.client',
        prop: linkedProperties.CLIENT,
        rules: [rules.REQUIRED],
        payload: [
          { param: 'projectId', from: [linkedProperties.PROJECT, 'id'] },
          { param: 'clientId', from: [linkedProperties.CLIENT, 'id'] },
          { param: 'unitId', from: [linkedProperties.UNIT, 'id'] },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.RESET_OR_SET_IF_ONE,
            to: linkedProperties.PROJECT,
          },
        ],
      },
      {
        type: schemaItemTypes.PROJECT,
        label: 'label.project',
        prop: linkedProperties.PROJECT,
        rules: [rules.REQUIRED],
        payload: [{ param: 'clientId', from: [linkedProperties.CLIENT, 'id'] }],
        onUpdate: [
          {
            type: onUpdateEffects.RESET_OR_SET_IF_ONE,
            to: linkedProperties.BUILDING,
          },
          {
            type: onUpdateEffects.RESET_OR_SET_IF_ONE,
            to: linkedProperties.CLIENT,
          },
        ],
      },
      {
        type: schemaItemTypes.BUILDING,
        label: 'label.building',
        prop: 'building',
        rules: [rules.REQUIRED],
        payload: [
          { param: 'projectId', from: [linkedProperties.PROJECT, 'id'] },
          { param: 'clientId', from: [linkedProperties.CLIENT, 'id'] },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.SET,
            to: linkedProperties.PROJECT,
            from: 'project',
          },
          {
            type: onUpdateEffects.RESET_OR_SET_IF_ONE,
            to: linkedProperties.UNIT,
          },
        ],
      },
      {
        type: schemaItemTypes.UNIT,
        label: 'label.unit',
        prop: 'unit',
        rules: [rules.REQUIRED],
        payload: [
          { param: 'buildingId', from: [linkedProperties.BUILDING, 'id'] },
          { param: 'projectId', from: [linkedProperties.PROJECT, 'id'] },
          { param: 'clientId', from: [linkedProperties.CLIENT, 'id'] },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.SET,
            to: linkedProperties.BUILDING,
            from: 'building',
          },
          {
            type: onUpdateEffects.RESET_OR_SET_IF_ONE,
            to: linkedProperties.ROOM,
          },
        ],
      },
    ],
  },
];
export const schema = [
  ...clientSchema,
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'label.contractor',
    children: [
      {
        type: schemaItemTypes.STRING,
        label: 'contractor.email',
        prop: 'contractorEmail',
        rules: [rules.EMAIL],
      },
    ],
  },
];

export const schemaWithRoom = [
  ...clientSchema,
  {
    type: schemaItemTypes.ROOM,
    label: 'label.room',
    prop: 'room',
    rules: [rules.REQUIRED],
    payload: [
      { param: 'unitId', from: [linkedProperties.UNIT, 'id'] },
      { param: 'buildingId', from: [linkedProperties.BUILDING, 'id'] },
      { param: 'projectId', from: [linkedProperties.PROJECT, 'id'] },
      { param: 'clientId', from: [linkedProperties.CLIENT, 'id'] },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.SET,
        to: linkedProperties.UNIT,
        from: 'unit',
      },
    ],
  },
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'Contractor',
    children: [
      {
        type: schemaItemTypes.STRING,
        label: 'contractor.email',
        prop: 'contractorEmail',
        rules: [rules.EMAIL],
      },
    ],
  },
];

export { schema as default };
